@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 3em;
  font-weight: 400;
}

h2 {
  font-size: 2.25em;
  font-weight: 500;
}

h4 {
  font-size: 1.875em;
  font-weight: 600;
}

h5 {
  font-size: 1.5em;
  font-weight: 600;
}

.modal::-webkit-scrollbar {
  display: none;
}

.owl-carousel .owl-item {
  transition: all 0.3s ease-in-out;
}

.owl-carousel .owl-item .card {
  padding: 30px;
  position: relative;
}

.owl-carousel .owl-stage-outer {
  overflow-y: auto !important;
  padding-bottom: 40px;
}

.owl-carousel .owl-item img {
  height: 200px;
  object-fit: cover;
  border-radius: 6px;
}

.owl-carousel .owl-item .card .name {
  position: absolute;
  bottom: -20px;
  left: 33%;
  color: #101c81;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: aquamarine;
  padding: 0.3rem 0.4rem;
  border-radius: 5px;
  box-shadow: 2px 3px 15px #3c405a;
}

.owl-carousel .owl-item .card {
  opacity: 0.2;
  transform: scale3d(0.8, 0.8, 0.8);
  transition: all 0.3s ease-in-out;
}

.owl-carousel .owl-item.active.center .card {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.owl-theme .owl-dots .owl-dot span {
  height: 20px;
  background: #2a6ba3 !important;
  border-radius: 2px !important;
  opacity: 0.8;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  height: 13px;
  width: 13px;
  opacity: 1;
  transform: translateY(2px);
  background: #83b8e7 !important;
}

@media(min-width: 480.6px) and (max-width: 575.5px) {
  .owl-carousel .owl-item .card .name {
      left: 24%;
  }
}

@media(max-width: 360px) {
  .owl-carousel .owl-item .card .name {
      left: 30%;
  }
}